<template>
  <div class="main-box">
    <a-result :status="status" :title="title">
      <template #subTitle>
        <span class="red">{{ time }}</span
        >{{ $t("second_automatic_jump") }}{{ name }}
      </template>
      <template #extra>
        <a-button type="primary" @click="goBack"
          >{{ $t("go_back") }}&nbsp;{{ name }}</a-button
        >
      </template>
    </a-result>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeUnmount, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      status: route.query.status || "404", //'success' | 'error' | 'info' | 'warning'| '404' | '403' | '500'
      title: route.query.title || "抱歉，您访问的页面不存在。",
      name: route.query.name || "首页",
      path: route.query.path || "/",
      time: 10,
      timer: null,
    });

    proxy.$message.destroy();

    state.timer = setInterval(() => {
      state.time = state.time - 1;
      if (state.time == 0) {
        goBack();
      }
    }, 1000);

    const goBack = () => {
      //清除定时器
      clearInterval(state.timer);
      if (route.path == "/error") {
        if (state.path == "/") {
          router.replace({ path: "/" });
        } else {
          router.replace({
            path: state.path,
            query: { id: state.taskId },
          });
        }
      }
    };

    onBeforeUnmount(() => {
      clearInterval(state.timer);
    });

    return {
      ...toRefs(state),
      goBack,
    };
  },
};
</script>

<style lang="less" scoped>
.main-box {
  width: 100%;
  height: 100%;
  .mixinFlex(center, center);
}
.ant-btn-primary {
  background: var(--theme);
  border-color: var(--theme);
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: var(--theme);
  border-color: var(--theme);
}
.red {
  color: red;
}
.orange {
  color: orange;
}
</style>
